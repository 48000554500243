import { ReactElement } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { ChevronRightRounded as RedirectIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { LOGO_PATH_LARGE } from 'common';
import { useAppSelector } from 'hooks';

/**
 * A view that displays an unknown page
 *
 * @returns The `NotFound` view
 */
export function NotFound(): ReactElement {
  const history = useHistory();

  const { session } = useAppSelector((state) => state.auth);

  /**
   * A function that redirects the user to safety
   */
  const handleRedirect = () => history.push(session ? '/' : '/inloggen');

  return (
    <Box
      p={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      minHeight="inherit"
      textAlign="center"
    >
      <img
        src={LOGO_PATH_LARGE}
        alt="Persbureau Heitink Logo"
        style={{ maxWidth: '90%' }}
      />
      <Box my={2} />
      <Typography variant="h4">Deze pagina is niet gevonden...</Typography>
      <Box my={2} />
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleRedirect}
      >
        Naar home <RedirectIcon />
      </Button>
    </Box>
  );
}
