import { ReactElement, useEffect, useState } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import { formatPrice } from 'common';
import { AsyncTable, PageTitle } from 'components';
import { useApi } from 'hooks';

/**
 * A view that displays all the rates
 *
 * @returns The `Rates` view
 */
export function Rates(): ReactElement {
  const api = useApi();

  const [rates, setRates] = useState<{
    photoPercentage: number;
    videoPercentage: number;
  }>({
    photoPercentage: 0,
    videoPercentage: 0,
  });
  const [ratesList, setRatesList] = useState<Record<string, any>[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  /**
   * A function that updates the rates
   */
  const updateRates = async () => {
    try {
      setRates({
        photoPercentage: 0,
        videoPercentage: 0,
      });
      setRatesList([]);
      setIsLoaded(false);
      setIsError(false);

      const { data: response } = await api.get('/customer/price');
      const { photoPercentage, videoPercentage, customers } = response.data;
      setRates({ photoPercentage, videoPercentage });
      setRatesList(customers);
      setIsLoaded(true);
    } catch {
      setIsError(true);
    }
  };

  /**
   * A hook that loads the data on page load
   */
  useEffect(() => {
    updateRates();
  }, []);

  return (
    <>
      <PageTitle
        category="Tarieven"
        subcategory="Media"
        subtitle={[
          'In onderstaande tabel staan de vergoedingen die Persbureau-Heitink ontvangt.',
          `Jouw aandeel is ${rates.photoPercentage}% voor foto's en ${rates.videoPercentage}% voor video's.`,
        ]}
      />
      <AsyncTable
        columns={[
          'Afnemer',
          'Foto',
          'Video',
          'Uitzending',
          'Krant',
          'Krant (klein)',
          'Bulk',
        ]}
        loading={!isLoaded && !isError}
        error={!!isError}
        empty={!!isLoaded && ratesList.length < 1}
        striped
      >
        {ratesList.map((rate) => (
          <TableRow key={rate.id}>
            <TableCell>{rate.description}</TableCell>
            <TableCell>{formatPrice(rate?.price.photo)}</TableCell>
            <TableCell>{formatPrice(rate?.price.video)}</TableCell>
            <TableCell>{formatPrice(rate?.price.broadcast)}</TableCell>
            <TableCell>{formatPrice(rate?.price.paper)}</TableCell>
            <TableCell>{formatPrice(rate?.price.paperSmall)}</TableCell>
            <TableCell>{formatPrice(rate?.price.bulk)}</TableCell>
          </TableRow>
        ))}
      </AsyncTable>
    </>
  );
}
