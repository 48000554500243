import { createTheme, responsiveFontSizes } from '@material-ui/core';
import { nlNL } from '@material-ui/core/locale';

/**
 * The custom theme for the application
 */
const customTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#0093D0',
      },
      secondary: {
        main: '#e6e6e6',
      },
    },
  },
  nlNL,
);

/**
 * A responsive version of the theme for the application
 */
export const theme = responsiveFontSizes(customTheme);
