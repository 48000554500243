import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';

import { Loader, LogoTitle, PageTitle } from 'components';
import { FeedbackType, emitFeedback } from 'features';
import { useApi, useAppDispatch } from 'hooks';

export function Settings() {
  const api = useApi();
  const dispatch = useAppDispatch();

  const [fetching, setFetching] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [submitBulkPressed, setSubmitBulkPressed] = useState(false);
  const [submitBundlePressed, setSubmitBundlePressed] = useState(false);
  const [submitCreditnotaPressed, setSubmitCreditnotaPressed] = useState(false);
  const [submitBankXmlPressed, setSubmitBankXmlPressed] = useState(false);

  const [sendingHelpChecked, setSendingHelpChecked] = useState<false>();

  /**
   * A function thet fetches and sets the settings data
   */
  const fetchSettingsData = useCallback(async () => {
    setFetching(true);
    const response = await api.get(`/settings`);
    setSendingHelpChecked(response.data.data.sendingHelp);
    setFetching(false);
  }, []);

  const handleChange = async (event: any) => {
    event?.preventDefault();
    setSubmitting(true);
    try {
      await api.patch(`/settings/help-sending`);
      setSendingHelpChecked(event.target.checked);
      fetchSettingsData();
    } finally {
      setSubmitting(false);
    }
  };

  const submitBulk = async () => {
    event?.preventDefault();
    setSubmitBulkPressed(true);
    try {
      await api.post(`/bulk/auto`);
    } finally {
      setSubmitBulkPressed(false);
    }
  };

  const submitBundle = async () => {
    event?.preventDefault();
    setSubmitBundlePressed(true);
    try {
      await api.post(`/bundle/auto`);
    } finally {
      setSubmitBundlePressed(false);
    }
  };

  const submitCreditnota = async () => {
    event?.preventDefault();
    setSubmitCreditnotaPressed(true);
    try {
      await api.post(`/pdf/creditnota/auto`);
    } finally {
      setSubmitCreditnotaPressed(false);
    }
  };

  const submitBankXml = async () => {
    event?.preventDefault();
    setSubmitBankXmlPressed(true);
    try {
      const response = await api.get(`/xml`, { responseType: 'blob' });
      const { data } = response;
      saveAs(data, `bankOpdracht_PBH_${uuidv4()}.xml`);
    } catch {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message:
            'Er is een fout opgetreden tijdens het downloaden van het document.',
        }),
      );
    } finally {
      setSubmitBankXmlPressed(false);
    }
  };
  /**
   * An effect which handles the loading of user data
   */
  useEffect(() => {
    fetchSettingsData();
  }, []);

  return (
    <>
      <PageTitle category="Applicatie" subcategory="Instellingen" />
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!fetching ? (
              <Paper>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <LogoTitle title="Instellingen" />
                      <Box my={2}>
                        <Divider />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sendingHelpChecked}
                            onChange={handleChange}
                            color="primary"
                            disabled={submitting}
                          />
                        }
                        label="Geholpen met versturen"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitBulk}
                        disabled={submitBulkPressed}
                      >
                        Automatische Bulk
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitBundle}
                        disabled={submitBundlePressed}
                      >
                        Automatische Bundel
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitCreditnota}
                        disabled={submitCreditnotaPressed}
                      >
                        Automatische Creditnota&apos;s
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitBankXml}
                        disabled={submitBankXmlPressed}
                      >
                        Download Backopdracht
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            ) : (
              <Loader />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
